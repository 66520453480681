<script lang="ts">
	import { fade } from 'svelte/transition'
	import {
		Dialog,
		DialogOverlay,
		DialogTitle,
		DialogDescription,
	} from '@rgossiaux/svelte-headlessui'

	let h: number

	export let is_open = true
</script>

{#if is_open}
	<Dialog
		open={is_open}
		on:close={() => (is_open = false)}
		class="fixed inset-0 z-30 h-screen w-screen overflow-y-auto"
		aria-labelledby="modal-title"
		role="dialog"
		aria-modal="true"
		static
	>
		<div transition:fade|global={{ duration: 150 }}>
			<DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

			<div class="webkit-fix flex items-end justify-center p-4 text-center sm:items-center sm:p-0">
				<div
					class="relative w-full transform overflow-hidden rounded bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6"
				>
					<div>
						<slot name="close-button" />
						<div class="text-base font-semibold leading-6 text-gray-900">
							<DialogTitle>
								<slot name="title" />
							</DialogTitle>
							<DialogDescription><slot name="description" /></DialogDescription>
							<div class="text-gray-600">
								<slot name="content" />
							</div>
						</div>
					</div>
					<slot name="buttons">
						<!-- <div class="mt-5 grid gap-3 sm:mt-6 sm:grid-flow-row-dense sm:grid-cols-2">
              <button
                type="button"
                class="mt-3 inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 sm:col-start-2 sm:mt-0"
                on:click={() => (is_open = false)}>Ok</button
              >
              <button
                on:click={() => (is_open = false)}
                type="button"
                class="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1"
                >Cancel</button
              >
            </div> -->
					</slot>
				</div>
			</div>
		</div>
	</Dialog>
{/if}

<style lang="postcss">
	/* Fixes the intentional 100vh bug in iOS Webkit */
	/* .webkit-fix {
		max-height: 100vh;
		max-height: 100svh;
	}*/
</style>
